import React, { useState } from "react";
import { Button, Grid, Tooltip, Divider } from "@mui/material";
import { Icon } from "@iconify/react";
import "./style.css";
import imageNotAvailable from "./No_Image_Available.jpg";
import WPButton from "../../CatalogueContainer/CatalogueCard/wpButton";

const icons = [
  { name: "Motor", icon: "ph:engine" },
  { name: "Puertas", icon: "mingcute:car-door-line" },
  { name: "Color", icon: "material-symbols:colors-sharp" },
  { name: "GNC", icon: "mdi:gas-tank-outline" },
  { name: "Transmision", icon: "solar:transmission-bold" },
  { name: "ABS", icon: "mdi:car-brake-abs" },
];

const NewProductCard = ({ data }) => {
  const [hoveredIcon, setHoveredIcon] = useState(null);

  const handleIconHover = (icon) => {
    setHoveredIcon(icon);
  };

  return (
    <div class="blog-card spring-fever">
      <div class="title-content">
        <h3>
        Noticia destacada
        </h3>
        <div class="intro">
          {" "}
        </div>
      </div>
      <div class="card-info">
        Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod
        tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim...
        <a href="#">
         Ver más
        </a>
      </div>
      <div class="utility-info">
        <ul class="utility-list">
          <li>
            03 jun 2017
          </li>
        </ul>
      </div>
      <div class="gradient-overlay"></div>
      <div class="color-overlay"></div>
    </div>
  );
};

export default NewProductCard;
