import React from "react";
import { Grid, Typography } from "@mui/material";
import { Icon } from "@iconify/react/dist/iconify.js";

export default function Footer() {
  return (
    <Grid
      container
      id="footerContainer"
      sx={{
        width:'100%',
        background: "#163E6A",
        color: "#fff",
        padding: "40px",
        marginTop: "20px",
      }}
    >
      <Grid item xs={6}>
        <Typography variant="body2" align="start">
          <a
            href="https://codercraft.com.ar/"
            target="_blank"
            rel="noreferrer"
            style={{ textDecoration: "none", color: "#FDB714" }}
          >
            <b>CoderCraft© </b>
          </a>
          {new Date().getFullYear()} <br /> Todos los derechos reservados.
        </Typography>
      </Grid>
      <Grid container item xs={6} justifyContent={"end"}>
        <a
          href="https://www.instagram.com/mafautos/"
          target="_blank"
          rel="noreferrer"
          style={{ textDecoration: "none" }}
        >
          <Icon
            icon="skill-icons:instagram"
            style={{ width: "28px", height: "28px", marginRight: "10px" }}
          />
        </a>
        <a href="https://wa.me/5493514033333" target="_blank" rel="noreferrer" style={{ textDecoration: "none" }}>
        <Icon
          icon="logos:whatsapp-icon"
          style={{ width: "28px", height: "28px" }}
        /></a>
      </Grid>
    </Grid>
  );
}
