import React, { useEffect, useState } from "react";
import { Grid, Typography, useMediaQuery, useTheme } from "@mui/material";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Pagination, Navigation } from "swiper/modules";
import NewProductCard from "./NewProductsCard";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import ProductService from "../../services/products.service";
import ProductSkeleton from "./ProductSkeleton";

const SwiperContainer = ({ items, renderItem,  }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down(600));
  return (
    <Swiper
      modules={[Navigation, Pagination]}
      navigation
      pagination={{ clickable: true }}
      spaceBetween={10}
      slidesPerView={4}
      breakpoints={{
        0: { slidesPerView: 1 },
        650: { slidesPerView: 2 },
        1100: { slidesPerView: 3 },
      }}
      style={{ width: isMobile ? '450px' : "100%" }}
    >
      {items.map((el) => (
        <SwiperSlide
          key={el.text}
          style={{ display: "flex", justifyContent: "center" }}
        >
          {renderItem(el)}
        </SwiperSlide>
      ))}
    </Swiper>
  );
};

const NoticiasDestacadas = () => {
  const [loading, setLoading] = useState(true);
  const [newProducts, setNewProducts] = useState([]);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("lg"));

  useEffect(() => {
    setLoading(true);
    const fetchProducts = async () => {
      try {
        const allProducts = await ProductService.getAllProducts();
        console.log(allProducts);
        const destacados = allProducts.filter(
          (producto) => producto.productoDestacado && producto.productoActivo
        );
        setNewProducts(destacados);
      } catch (error) {
        console.error("Error al obtener los productos:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchProducts();
  }, []);

  return (
    <Grid
      item
      container
      xs={12}
      id="newProductsContainer"
      sx={{ justifyContent: "center", margin: "20px 0", padding: "20px 0" }}
    >
      <Grid
        item
        xs={10}
        container
        flexDirection="row"
        sx={{ minHeight: "350px" }}
      >
        {loading ? (
          <ProductSkeleton />
        ) : (
          isMobile ? (
            <SwiperContainer
              items={[1,2,3]}
              renderItem={(el) => (
                <NewProductCard />
              )}
             
            />
          ) : (
            <>
            <Grid item container justifyContent={'center'} xs={12} sm={6} md={4}>
              <NewProductCard />
            </Grid>
            <Grid item container justifyContent={'center'} xs={12} sm={6} md={4}>
              <NewProductCard />
            </Grid>
            <Grid item container justifyContent={'center'} xs={12} sm={6} md={4}>
              <NewProductCard />
            </Grid>
          </>
          )
       
        )}
      </Grid>
    </Grid>
  );
};

export default NoticiasDestacadas;
