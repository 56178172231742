import { Grid, Typography, useMediaQuery, useTheme } from "@mui/material";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination } from "swiper/modules";
import "swiper/css"; // Importa los estilos básicos de Swiper
import StaticCard from "./StaticCard";
import { useState } from "react";
import { inSite, staticCards } from "./constants";
import "swiper/css/navigation";
import "swiper/css/pagination";
import ModalGestionOnline from "./Modal";

const SwiperContainer = ({ items, renderItem }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down(600));

  return (
    <Swiper
      modules={[Navigation, Pagination]}
      navigation
      pagination={{ clickable: true }}
      spaceBetween={10}
      slidesPerView={4}
      breakpoints={{
        0: { slidesPerView: 1 },
        650: { slidesPerView: 2 },
        800: { slidesPerView: 3 },
        1000: { slidesPerView: 4 },
      }}
      style={{ width: isMobile ? "450px" : "100%" }}
    >
      {items.map((el) => (
        <SwiperSlide
          key={el.text}
          style={{ display: "flex", justifyContent: "center" }}
        >
          <Grid container>{renderItem(el)}</Grid>
        </SwiperSlide>
      ))}
    </Swiper>
  );
};

const GestionOnline = () => {
  const [data, setData] = useState({});
  const [open, setOpen] = useState(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("lg"));

  const handleClick = (data) => {
    setData(data);
  
    if (data.id === 8) {
      window.open(
        "https://sigloservicios.com/ConsultasWeb/selecciontasa.aspx?33678663609",
        "_blank"
      );
    } else {
      setOpen(true);

    }
  
  };

  return (
    <Grid container id="GestionOnline" mt={2} spacing={2}>
      <Grid item xs={12} container justifyContent={"center"}>
        <Typography
          textAlign={"center"}
          fontSize={40}
          fontWeight={600}
          color={"#163E6A"}
        >
          Servicios más consultados
        </Typography>
      </Grid>

      <Grid
        container
        mt={3}
        item
        xs={12}
        id="StaticCardsContainer"
        sx={{ justifyContent: "center" }}
      >
        {isMobile ? (
          <SwiperContainer
            items={[...staticCards, ...inSite]}
            renderItem={(el) => (
              <StaticCard
                data={el}
                setCardSelected={(data) => handleClick(data)}
              />
            )}
          />
        ) : (
          <Grid item container xs={10}>
            {staticCards.map((el) => (
              <StaticCard
                key={el.text}
                data={el}
                setCardSelected={(data) => handleClick(data)}
              />
            ))}
          </Grid>
        )}
      </Grid>

      <Grid item mt={3} container xs={12} justifyContent={"center"}>
        {!isMobile && (
          <Grid item container xs={10}>
            {inSite.map((el) => (
              <StaticCard
                key={el.text}
                data={el}
                setCardSelected={(data) => handleClick(data)}
              />
            ))}
          </Grid>
        )}
      </Grid>
      <ModalGestionOnline
        open={open}
        data={data}
        handleClose={() => setOpen(false)}
      />
    </Grid>
  );
};

export default GestionOnline;
