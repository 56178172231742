import React from "react";
import './style.css';
import imagen from '../../assets/logo.png'
const Loader = () => {
  return (
    <div className="loader">
      {/* Rueda SVG */}
    <img src={imagen} alt="image" />
      <div className="road">
      </div>
    </div>
  );
};

export default Loader;
