import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "../../store/authSlice";
import { Icon } from "@iconify/react";
import {
  Box,
  Toolbar,
  IconButton,
  Grid,
  Typography,
  AppBar,
  Drawer,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Tooltip,
  Divider,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { useNavigate, useLocation } from "react-router-dom";
import logo from "../../assets/logo.png";
import { setActiveTab } from "../../store/tabSlice";

export default function Layout({ children }) {
  const [open, setOpen] = useState(false);
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const [fullName, setFullName] = useState("");
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("lg"));

  useEffect(() => {
    console.log("isAuthenticated", isAuthenticated);
    const storedFullName = localStorage.getItem("fullName");
    if (storedFullName) {
      setFullName(storedFullName);
    }
  }, [isAuthenticated]);

  const toggleDrawer = (newOpen) => () => {
    setOpen(newOpen);
  };

  const handleLogout = () => {
    dispatch(logout());
    navigate("/login");
  };

  const handleTabChange = (tabValue) => {
    dispatch(setActiveTab(tabValue));
  };

  return (
    <Grid
      data-testid="layout-test"
      sx={{ display: "flex", flexDirection: "column", height: "100vh" }}
    >
      <AppBar
        position="static"
        id="este es el appbar"
        style={{
          position: "absolute",
          backgroundColor: "rgba(22, 62, 106, 0.6)",
        }}
      >
        <Toolbar
          id="toolbar"
          sx={{
            display: "flex",
            justifyContent: "center",
            position: "relative",
          }}
        >
          <Grid container>
            <Grid container item xs={6} lg={2} height={"60px"} sx={{justifyContent: {xs: 'start', lg:'center'}}}>
              <img
                src={logo}
                alt="maf-logo"
                width={"60px"}
                onClick={() => navigate("/")}
                style={{ cursor: "pointer" }}
              />
            </Grid>

            {(isAuthenticated || isSmallScreen) && (
              <Grid container item xs={6} justifyContent={"flex-end"}>
                <IconButton
                  size="large"
                  edge="start"
                  color="inherit"
                  aria-label="menu"
                  sx={{}}
                  onClick={toggleDrawer(true)}
                >
                  <Icon icon="iconamoon:menu-burger-horizontal-fill" />
                </IconButton>
              </Grid>
            )}

            {location.pathname === "/" && (
              <Grid
                item
                xs
                lg={8}
                justifyContent={'center'}
                alignItems={'center'}
                sx={{ display: { xs: "none", lg: "flex" } }}
              >
                <a
                  href="#newProductsContainer"
                  style={{
                    textDecoration: "none",
                    color: "white",
                    padding: "0px 10px",
                    fontSize: "18px",
                    fontWeight: 500,
                  }}
                >
                  Gobierno
                </a>
                <a
                  href="#catalogue"
                  style={{
                    textDecoration: "none",
                    color: "white",
                    padding: "0px 10px",
                    fontSize: "18px",
                    fontWeight: 500,
                  }}
                >
                  Portal de Comunicación
                </a>
                <a
                  href="#"
                  style={{
                    textDecoration: "none",
                    color: "white",
                    padding: "0px 10px",
                    fontSize: "18px",
                    fontWeight: 500,
                  }}
                  onClick={() => handleTabChange("1")}
                >
                  Noticias
                </a>
              </Grid>
            )}
            {!isSmallScreen && 
            <Grid container item lg={2}  height={"60px"}></Grid>
            }
          </Grid>
        </Toolbar>
      </AppBar>
      <Box component="nav">
        <Drawer
          sx={{
            "& .MuiPaper-root": {
              width: "100%",
              backgroundColor: "rgba(22, 62, 106, 0.7)",
              backdropFilter: "blur(8px)",
            },
          }}
          open={open}
          onClose={toggleDrawer(false)}
        >
          <Grid
            item
            xs={12}
            display={"flex"}
            justifyContent={"space-between"}
            sx={{ height: "80px" }}
          >
            <img
                src={logo}
                alt="maf-logo"
                onClick={() => navigate("/")}
                style={{ cursor: "pointer", paddingLeft: '20px' }}
              />
            <Icon
              icon="ic:round-close"
              width={30}
              height={30}
              onClick={toggleDrawer(false)}
              style={{
                color: "#fff",
                margin: "20px 20px 0 0",
                cursor: "pointer",
              }}
            />
          </Grid>
          <Divider sx={{ borderColor: "#38B34A" }} />
          {location.pathname === "/" && (
            <Grid
              container
              spacing={2}
              mt="20px"
              pl={"30px"}
              sx={{ display: { xs: "flex", lg: "none" } }}
            >
              <Grid item xs={12}>
                <a
                  href="#newProductsContainer"
                  style={{
                    textDecoration: "none",
                    color: "#fff",
                    fontSize: "18px",
                    fontWeight: 500,
                  }}
                >
                  Gobierno
                </a>
              </Grid>
              <Grid item xs={12}>
                {" "}
                <a
                  href="#catalogue"
                  style={{
                    textDecoration: "none",
                    color: "#fff",

                    fontSize: "18px",
                    fontWeight: 500,
                  }}
                >
                  Portal de Comunicación
                </a>
              </Grid>
              <Grid item xs={12} paddingLeft={0}>
                {" "}
                <a
                  href="#catalogue"
                  style={{
                    textDecoration: "none",
                    color: "#fff",

                    fontSize: "18px",
                    fontWeight: 500,
                  }}
                >
                  Noticias
                </a>
              </Grid>
            </Grid>
          )}
        </Drawer>
      </Box>
      <Grid container id="main" sx={{ height: "100vh" }}>
        {children}
      </Grid>
    </Grid>
  );
}
