export const staticCards = [
  {
    id: 1,
    name: "Licencia de Conducir",
    icon: "fa:drivers-license-o",
    text: "Texto de licencia",
  },
  {
    id: 2,
    name: "Registro Civil",
    icon: "game-icons:finger-print",
    text: "Texto de Registro",
  },
  {
    id: 3,
    name: "Boletín Informativo",
    icon: "ion:newspaper-outline",
    text: "Texto de Boletín",
  },
  {
    id: 4,
    name: "Reclamos",
    icon: "hugeicons:complaint",
    text: "Texto de Reclamos",
  },

];

export const inSite = [
  {
    id: 5,
    name: "Centro de Salud",
    icon: "iconoir:healthcare",
  },
  {
    id: 6,
    name: "Consejo Deliberante",
    icon: "streamline:capitol",
  },
  {
    id: 7,
    name: "Ambiente",
    icon: "fluent:tree-deciduous-24-regular",
  },
  {
    id: 8,
    name: "Portal de pago",
    icon: "ion:wallet-outline",
  },
 
];
