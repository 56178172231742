import React, { useEffect } from "react";
import { Button, Divider, Grid, Typography, useMediaQuery } from "@mui/material";
import NoticiasDestacadas from "../../components/NoticiasDestacadas";
import CatalogueContainer from "../../components/CatalogueContainer";
import Footer from "../../components/Footer";
import Typed from "typed.js";
import GestiónOnline from "../../components/GestiónOnline";
import { motion } from "framer-motion";
import { useInView } from "react-intersection-observer";
import newHero from "../../assets/home.jpeg";
import { lightGreen } from "@mui/material/colors";
import { Icon } from "@iconify/react/dist/iconify.js";
import styles from "./styles.css";


export default function Home() {
  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down("sm"));




  const fadeInUp = {
    hidden: { opacity: 0, y: 50 },
    visible: { opacity: 1, y: 0 },
  };

  // Use useInView to detect when elements are in the viewport
  const [heroRef, heroInView] = useInView({
    triggerOnce: true,
    threshold: 0.1,
  });

  const [tabsRef, tabsInView] = useInView({
    triggerOnce: true,
    threshold: 0.1,
  });

  const [newProductsRef, newProductsInView] = useInView({
    triggerOnce: true,
    threshold: 0.1,
  });

  const [catalogueRef, catalogueInView] = useInView({
    triggerOnce: true,
    threshold: 0.1,
  });

  return (
    <Grid
      container
      id="homeContainer"
      
    >
      <Grid
        container
        id="heroContainer"
        sx={{
          height: "100vh",
          backgroundImage: `url(${newHero})`,
          backgroundSize: "cover",
        }}
      >
        <Grid
          container
          item
          xs={12}
          md={8}
          lg={6}
          xl={5}
          style={{
            color: "white",
            justifyContent: "center",
            alignItems: "center",
            // backgroundImage: `url(${heroBg})`,
            // backgroundSize: "cover",
          }}
        >
          <Grid
            item
            xs={10}
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              backgroundColor: 'rgba(22, 62, 106, 0.6)',
              alignItems: "left",
              p: 5,
              borderRadius: '12px'
            }}
          >
            <motion.div
              ref={heroRef}
              initial="hidden"
              animate={heroInView ? "visible" : "hidden"}
              variants={fadeInUp}
              transition={{ duration: 0.6 }}
            >
              <Typography
                sx={{ textAlign: "left", fontWeight: 600, fontSize: {xs: '30px', sm: '56px'} }}
              >
               
                 Municipalidad <br /> de La Granja 
               
               
              </Typography>
              <Typography fontSize={18} sx={{ textAlign: "left" }}>
                <span style={{ color: "#FFF" }}>
                  Creciendo para nuestros vecinos
                </span>{" "}
              </Typography>

              <Grid item mt={3}>
                <Button variant="primary" >Explorar más</Button>
              </Grid>
              
            </motion.div>
          </Grid>
        </Grid>
      </Grid>
    
     
      <motion.div
        ref={tabsRef}
        initial="hidden"
        animate={tabsInView ? "visible" : "hidden"}
        variants={fadeInUp}
        transition={{ duration: 0.6 }}
        style={{ width: "100%" }}
      >
        <GestiónOnline />
      </motion.div>
     
     <motion.div
        ref={newProductsRef}
        initial="hidden"
        animate={newProductsInView ? "visible" : "hidden"}
        variants={fadeInUp}
        transition={{ duration: 0.6 }}
        style={{ width: "100%" }}
      >
        <NoticiasDestacadas />
      </motion.div>
      {/*
      <motion.div
        ref={catalogueRef}
        initial="hidden"
        animate={catalogueInView ? "visible" : "hidden"}
        variants={fadeInUp}
        transition={{ duration: 0.6 }}
      >
        <CatalogueContainer />
      </motion.div> */}
      <Footer />
    </Grid>
  );
}
